const prismicLanguagesToRouter = {
  'en-us': 'en',
  'en-gb': 'en-gb',
  'nl-nl': 'nl',
  'fr-fr': 'fr',
  'de-de': 'de',
  'es-mx': 'es',
  'pl-pl': 'pl',
  he: 'he',
};

const routerToPrismicLanguages = {
  en: 'en-us',
  'en-gb': 'en-gb',
  nl: 'nl-nl',
  fr: 'fr-fr',
  de: 'de-de',
  es: 'es-mx',
  pl: 'pl-pl',
  he: 'he',
};

module.exports = {
  prismicLanguagesToRouter,
  routerToPrismicLanguages,
};
