import BraintreeConfig from '../../services/braintree/shared/braintree-config'
import { Address } from './CountryConfig'
import IPaymentFormInfo from './IPaymentFormInfo'

export abstract class Basket {
  constructor(
    braintree_payment_nonce: string,
    paymentMethod: PaymentMethod,
    paymentInfo: IPaymentFormInfo,
    recaptchaToken: string,
    deviceData: string
  ) {
    this.netAmount = paymentInfo.netAmount
    this.coverTransactionCosts = paymentInfo.coverTransactionCosts
    this.totalAmount = BraintreeConfig.getTotalAmountString(
      paymentInfo.netAmount,
      paymentInfo.coverTransactionCosts
    )
    this.coveredAmount = paymentInfo.coverTransactionCosts
      ? (
          paymentInfo.netAmount * BraintreeConfig.CoveredTransactionFactor -
          paymentInfo.netAmount
        ).toFixed(2)
      : '0.00'
    this.braintree_payment_nonce = braintree_payment_nonce
    this.currency_code = paymentInfo.currencyCode
    this.address = paymentInfo.address
    this.paymentMethod = paymentMethod
    this.extraImpact = { agreeToGiftAid: paymentInfo.agreeToGiftAid }
    this.recaptchaToken = recaptchaToken
    this.deviceData = deviceData
  }

  abstract readonly transactionType: string
  recaptchaToken: string
  totalAmount: string
  coveredAmount: string
  coverTransactionCosts: boolean
  address: Address
  extraImpact: ExtraImpact
  braintree_payment_nonce: string
  currency_code: CurrencyCode
  netAmount: number
  paymentMethod: PaymentMethod
  deviceData: string
}

//single payments can have multiple designations
export class SinglePaymentBasket extends Basket {
  transactionType = 'SINGLE'
  localPaymentId?: string
  paymentProvider: LocalPaymentProviders
  constructor(
    braintree_payment_nonce: string,
    paymentMethod: PaymentMethod,
    paymentInfo: IPaymentFormInfo,
    recaptchaToken: string,
    deviceData: string,
    localPaymentId: string = null,
    paymentProvider: LocalPaymentProviders = null
  ) {
    super(
      braintree_payment_nonce,
      paymentMethod,
      paymentInfo,
      recaptchaToken,
      deviceData
    )
    this.items = paymentInfo.basketItems
    this.localPaymentId = localPaymentId
    this.paymentProvider = paymentProvider
  }

  items: DesignationItem[]
}

//multiple payments always go to the general fund
export class RecurringPaymentBasket extends Basket {
  transactionType = 'RECURRING'
  constructor(
    braintree_payment_nonce: string,
    paymentMethod: PaymentMethod,
    paymentInfo: IPaymentFormInfo,
    recaptchaToken: string,
    deviceData: string
  ) {
    super(
      braintree_payment_nonce,
      paymentMethod,
      paymentInfo,
      recaptchaToken,
      deviceData
    )
  }
}

//It will always start off true
export class ValidationResult {
  constructor() {
    this.errorMessages = []
  }
  isValid: boolean = true
  errorMessages?: string[]
  errorObject?: any
}

export class MerchantAccountResult extends ValidationResult {
  merchantAccount: any
}

export class PaymentResult extends ValidationResult {
  constructor(paymentMethod: PaymentMethod) {
    super()
    this.paymentMethod = paymentMethod
  }

  paymentMethod: PaymentMethod
  giftAidEmailResult?: any
  thankyouEmailResult?: any
  subscribedToMailingListSuccessfully?: boolean
  recaptchaError?: boolean
  errorCodes?: string[]
}
export class SinglePaymentResult extends PaymentResult {
  //for local payments, to ensure we don't send double emails
  alreadyProcessed?: boolean

  constructor(paymentMethod: PaymentMethod) {
    super(paymentMethod)
  }

  transactionId: any
}

export class RecurringPaymentResult extends PaymentResult {
  customerId: any
  subscriptionId: any

  constructor(paymentMethod: PaymentMethod) {
    super(paymentMethod)
  }
}

export class DesignationItem {
  id: string
  name: string
  amount: number
  note: string
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  PAYPAL = 'PAYPAL',
  GOOGLE = 'GOOGLE',
  VENMO = 'VENMO',
  APPLE = 'APPLE',
  LOCAL = 'LOCAL',
}

export enum BraintreeEnvironment {
  SANDBOX = 'SANDBOX',
  PRODUCTION = 'PRODUCTION',
}

export enum BraintreeBranch {
  DE = 'DE',
  NL = 'NL',
  US = 'US',
  UK = 'UK',
}

export enum CurrencyCode {
  USDollar = 'USD',
  Euro = 'EUR',
  Pound = 'GBP',
  Shekel = 'ILS',
}

export enum LocalPaymentProviders {
  iDeal = 'ideal',
  Klarna = 'sofort',
}

export enum PaymentErrorType {
  CreditCard = 'CreditCard',
  Server = 'Server',
  Paypal = 'Paypal',
  Recaptcha = 'Recaptcha',
  Validation = 'Validation',
  ThreeDS = 'ThreeDS',
  Google = 'Google',
  ApplePay = 'ApplePay',
  Venmo = 'Venmo',
  Klarna = 'Klarna',
  iDeal = 'iDeal',
}

export class ExtraImpact {
  agreeToGiftAid: boolean
  //coverTransactionCosts: boolean
}
