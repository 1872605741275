const styles = (theme) => ({
  headerBg: {
    backgroundImage: `url('/headertopbg.png')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    zIndex: 9,
    position: 'absolute',
    width: '100%',
  },
  header: {
    display: 'flex',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .logo': {
      width: 123,
      [theme.breakpoints.down('sm')]: {
        width: 103,
      },
      [theme.breakpoints.down('xs')]: {
        width: 93,
        marginLeft: 0,
      },
    },
    '& img': {
      marginLeft: theme.spacing(2),
      display: 'block',
    },
    '& .menu': {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& a.menuLink': {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        cursor: 'pointer',
        color: '#fff',
        fontFamily: 'Raleway,Assistant',
        fontSize: 14,
        fontWeight: 800,
        margin: theme.spacing(0, 4),
        textDecoration: 'none',
        textTransform: 'uppercase',
        '& img': {
          filter:
            'invert(100%) sepia(31%) saturate(2202%) hue-rotate(257deg) brightness(120%) contrast(97%)',
        },
      },
      '& a.dark': {
        color: '#4c4c78',
        '& img': {
          filter:
            'invert(26%) sepia(4%) saturate(6466%) hue-rotate(202deg) brightness(99%) contrast(80%) !important',
        },
      },
      '& button': {
        fontSize: 14,
        width: 'fit-Content',
        minWidth: 'unset',
        marginLeft: theme.spacing(2),
        width: 88,
        borderRadius: 4,
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& .menuIcon': {
      display: 'none',
      marginRight: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(3),
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(2),
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        marginRight: theme.spacing(1),
      },
      '& img': {
        filter:
          'invert(100%) sepia(31%) saturate(2202%) hue-rotate(257deg) brightness(120%) contrast(97%)',
      },
      '& a.dark': {
        color: '#4c4c78',
        '& img': {
          filter:
            'invert(26%) sepia(4%) saturate(6466%) hue-rotate(202deg) brightness(99%) contrast(80%) !important',
        },
      },
    },
    '& .mobileMenu': {
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
      '& .drawerBtnWhite': {
        color: '#fff',
      },
      '& .drawerBtnBlue': {
        color: '#3a3a5d',
      },
    },
  },
  drawerMenu: {
    '& a': {
      color: '#4c4c78',
      fontFamily: 'Raleway,Assistant',
      fontSize: 14,
      fontWeight: 800,
      marginBottom: theme.spacing(2),
    },
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .list': {
      flex: 1,
      paddingRight: theme.spacing(2),
    },
    '& .button': {
      padding: theme.spacing(2),
    },
  },
})

export default styles
