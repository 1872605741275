import getConfig from 'next/config'
import { BraintreeBranch, BraintreeEnvironment, CurrencyCode, MerchantAccountResult, ValidationResult } from '../../../models/braintree/DonationModels'
import * as braintreeClient from 'braintree-web/client'
import BraintreeClientResult from '../../../models/braintree/BraintreeClientResult'
import * as btDataCollector from 'braintree-web/data-collector'
import Logger from '../../error-logging-service'

function getBranchConfig(btBranch: BraintreeBranch) {
    return getConfig()
        .publicRuntimeConfig.braintreeClientTokens[btBranch]
}

function validateBranch(branchCode: string): boolean {

    return Object.values(BraintreeBranch).includes(branchCode as BraintreeBranch)
}

function validateCurrency(currencyCode: string): boolean {
    return Object.values(CurrencyCode).includes(currencyCode as CurrencyCode)
}

export interface DeviceDataResult {
    deviceData?: string
    success: boolean
    error?: any
}

//this can be used from front end and server
const BraintreeConfig = {

     CoveredTransactionFactor: 1.05,
     branchSupportsGiftAid: (branch: BraintreeBranch):boolean=>  {
        return branch === BraintreeBranch.UK
     },
     getTotalAmountNumber: (netAmount: number,coverTransactionCosts: boolean)=> {

        if (netAmount === null || netAmount === undefined){
            return 0
        }
        return coverTransactionCosts ? (netAmount * BraintreeConfig.CoveredTransactionFactor) : netAmount
     },
     getTotalAmountString: (netAmount: number,coverTransactionCosts: boolean)=> {
        
        if (netAmount === null || netAmount === undefined){
            return ''
        }
        return coverTransactionCosts ? (netAmount * BraintreeConfig.CoveredTransactionFactor).toFixed(2) : netAmount.toFixed(2)
     },
     getDeviceData: async (token: string, isPaypal: boolean) : Promise<DeviceDataResult>=> {
       
        try {

            if (!token){
                throw "Token was null when getting device data"
            }
            const deviceDataInstance = await btDataCollector.create({authorization:token, paypal: isPaypal})

            return {success: true, deviceData: deviceDataInstance.deviceData}
        }
        catch (error){
            return {
                success: false,
                error
            }
        }   
        finally{
            Logger.logTrace("finished getting device data")
        }
        
     },
    getMerchantAccountId: (btBranch: BraintreeBranch, currencyCode: CurrencyCode) => {
        
        const mAccountId = getBranchConfig(btBranch).merchantAccountIds[currencyCode]

        if (!mAccountId){
            throw Error("Could not get merchantAccountId for "+btBranch + " "+ currencyCode)
        }

        return mAccountId
    },
    getClientToken: (btBranch: BraintreeBranch): string => {

        const branchConfig = getBranchConfig(btBranch)

        if (!branchConfig){
            throw Error("Could not get branch config for "+btBranch)
        }

        return branchConfig.tokenisationKey
    },

    //this should be the ONLY place to get the braintree envirnoment from
    // never access the config directly apart from in this function
    getBraintreeEnvironment: (): BraintreeEnvironment => {

        let env = getConfig().publicRuntimeConfig.braintreeEnvironment as BraintreeEnvironment

        return env
    },
    validateBranch: validateBranch,
    validateCurrency: validateCurrency,
    validateBraintreeBranchAndCurrency: (branchCode: string, currencyCode: string): ValidationResult => {

        let result = new ValidationResult()

        let branchResult = validateBranch(branchCode)

        if (!branchResult) {
            result.isValid = false
            result.errorMessages.push(`${branchCode} is not a valid braintree branch code`)
        }

        let currencyResult = validateCurrency(currencyCode)
        if (!currencyResult) {
            result.isValid = false
            result.errorMessages.push(`${currencyCode} is not a valid braintree currency code`)
        }

        return result
    }
}

export default BraintreeConfig