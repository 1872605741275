import React, {useContext} from 'react'

const LocalityContext = React.createContext({
  country: '',
  language: '', // possibly add english as a default here
  setLanguage: null,
  setCountry: null
})

export const LocalityProvider = LocalityContext.Provider
export const LocalityConsumer = LocalityContext.Consumer

export const useLocality = () => {
  return useContext(LocalityContext);
};

export default LocalityContext