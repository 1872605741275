import { Address } from "../models/braintree/CountryConfig";
import { CurrencyCode } from "../models/braintree/DonationModels";

export const allCurrencies : Currency[] = [
    {code: CurrencyCode.USDollar, symbol: "$"},
    {code: CurrencyCode.Pound, symbol: "£"},
    {code: CurrencyCode.Shekel, symbol: "₪"},
    {code: CurrencyCode.Euro, symbol: "€"},
]

const availableCurrencies = {
    [CurrencyCode.USDollar]: "$",
    [CurrencyCode.Pound]: "£",
    [CurrencyCode.Shekel]: "₪",
    [CurrencyCode.Euro]: "€"
};

export class Currency {
    code: CurrencyCode
    symbol: string
    static get = (code: CurrencyCode): Currency => {
        return { code, symbol: availableCurrencies[code] }
    }
  }
