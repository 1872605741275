import { createStyles, Theme } from '@material-ui/core/styles'

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 'auto',
    bottom: 0,
    width: '120px',
    height: '80px',
    margin: 'auto',
    zIndex: 1000,
  },
  wrapper: {
    transform: 'rotate(-90deg) translateY(-36px)',
  },
  donateButton: {
    display: 'flex',
    gap:'8px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
    height: theme.spacing(6),
    background: theme.palette.primary[500],
    color: 'white',
    textDecoration: 'none',
    borderRadius: '0 0 6px 6px',
    fontFamily: theme.typography.fontFamily,
    fontSize: '0.875rem',
    textTransform:'uppercase',
    fontWeight: 900,
    '& img': {
      animation: '$pulse 2s infinite',
    }
  },
  "@keyframes pulse": {
    "0%": {
      transform: 'scale(1)',
    },
    "75%": {
      transform: 'scale(1.3)',
    },
    "100%": {
      transform: 'scale(1)'
    }
  }
})

export default styles