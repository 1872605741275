const styles = (theme) => ({
  subHeader: {
    display: "flex",
    height: 40,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#254f72",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      height: "unset",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      textAlign: 'center',
      flexDirection:'column',
    },
    "& p": {
      fontSize: 12,
    },
    "& button": {
      height: 20,
      padding: "0px 20px",
      fontSize: 10,
      borderWidth: 1,
      minWidth: "fit-content",
      marginRight: 16,
      marginLeft: 16,
      [theme.breakpoints.down("xs")]: {
        marginRight:0,
        marginLeft: 0,
        marginTop: theme.spacing(1),
      },
      "&:hover": {
        borderWidth: 1,
      },
    },
  },
});

export default styles;
