import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { RichText } from 'prismic-reactjs'
import Button from '@material-ui/core/Button'
import Link from 'next/link'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Drawer from '@material-ui/core/Drawer'
import style from './style'
import { useLayout } from '../../utils/layout-context'
import { linkResolver } from '../../prismicio'
import CustomLink from '../CustomLink'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { PrismicNextImage } from '@prismicio/next'
import { isFilled } from '@prismicio/client'

const Header = ({ classes }) => {
  const [open, setOpen] = React.useState(false)
  const { layout, headerColor } = useLayout()
  const router = useRouter()
  const [headerColorCurrent, setHeaderColorCurrent] =
    React.useState(headerColor)

  const handleSidebarOpen = () => {
    setOpen(true)
  }

  const handleSidebarClose = () => {
    setOpen(false)
  }

  const clickMobileItem = (menuItem) => {
    if (menuItem.link.link_type === 'Web') {
      window.open(menuItem.link.url)
    } else {
      router.push(linkResolver(menuItem.link))
    }

    setOpen(false)
  }

  return (
    <div
      className={clsx({
        [classes.headerBg]: !headerColor,
      })}
    >
      <Container className={classes.header}>
        <CustomLink link={layout.nav_logo_link}>
          {headerColor ? (
            <img src={layout.colored_logo.url} className="logo" alt="" />
          ) : (
            <img src={layout.white_logo.url} className="logo" alt="" />
          )}
        </CustomLink>
        <div className="menu">
          {layout.menu_items.map((menu_item, key) => {
            if (isFilled.image(menu_item.icon)) {
              return (
                <Link
                  href={
                    menu_item.link.link_type == 'Web'
                      ? menu_item.link.url
                      : linkResolver(menu_item.link)
                  }
                  target={menu_item.link?.target || undefined}
                  key={key}
                  className={clsx('menuLink', {
                    dark: headerColor,
                  })}
                >
                  <PrismicNextImage
                    field={menu_item.icon}
                    alt={menu_item.icon.alt | ''}
                    width={14}
                    height={14}
                  />
                  <span className="sr-only">
                    {RichText.asText(menu_item.link_title) || 'Search'}
                  </span>
                </Link>
              )
            }
            return (
              <Link
                href={
                  menu_item.link.link_type == 'Web'
                    ? menu_item.link.url
                    : linkResolver(menu_item.link)
                }
                target={menu_item.link?.target || undefined}
                key={key}
                className={clsx('menuLink', {
                  dark: headerColor,
                })}
              >
                {RichText.asText(menu_item.link_title)}
              </Link>
            )
          })}
          <CustomLink link={layout.give_button_link}>
            <Button variant="contained" color="primary" size="small">
              {RichText.asText(layout.give_button_text)}
            </Button>
          </CustomLink>
        </div>
        <div className="menuIcon">
          {layout.menu_items
            .filter((menu_item) => isFilled.image(menu_item.icon))
            .map((menu_item, key) => {
              return (
                <Link
                  href={
                    menu_item.link.link_type == 'Web'
                      ? menu_item.link.url
                      : linkResolver(menu_item.link)
                  }
                  target={menu_item.link?.target || undefined}
                  key={key}
                  className={clsx('menuLink', {
                    dark: headerColor,
                  })}
                >
                  <PrismicNextImage
                    field={menu_item.icon}
                    alt={menu_item.icon.alt | ''}
                    width={14}
                    height={14}
                  />
                  <span className="sr-only">
                    {RichText.asText(menu_item.link_title) || 'Search'}
                  </span>
                </Link>
              )
            })}
        </div>
        <div className="mobileMenu">
          <IconButton
            aria-label="Drawer"
            component="span"
            className={headerColor ? 'drawerBtnBlue' : 'drawerBtnWhite'}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onClick={handleSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Drawer anchor="right" open={open} onClose={handleSidebarClose}>
          <div className={classes.sidebar}>
            <List className="list">
              {layout.menu_items
                .filter((menu_item) => !isFilled.image(menu_item.icon))
                .map((menu_item, index) => (
                  <ListItem
                    button
                    key={index}
                    className="listItem"
                    onClick={() => clickMobileItem(menu_item)}
                  >
                    <ListItemText
                      primary={RichText.asText(menu_item.link_title)}
                    />
                  </ListItem>
                ))}
            </List>
            <CustomLink link={layout.give_button_link} className="button">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpen(false)}
              >
                {RichText.asText(layout.give_button_text)}
              </Button>
            </CustomLink>
          </div>
        </Drawer>
      </Container>
    </div>
  )
}

Header.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default withStyles(style)(Header)
