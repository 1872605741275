import React, { useState } from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import InputBase from "@material-ui/core/InputBase";
import style from "./style";
import { useLayout } from "../../utils/layout-context";
import { useRouter } from "next/router";
import appConstants from "../../utils/constants/vfi-constants";
import { motion } from "framer-motion";
import {
  vSlideBottomFade,
  vSlideRightFade,
  vTextFade,
} from "../../utils/animations";
import { useSnackbar } from "notistack";

const NewsUpdate = ({ classes }) => {
  const { layout } = useLayout();
  const locale = useRouter().locale;
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const reset = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setEmailValid(false)
  }

  const send = async ({ email, firstName, lastName }) => {
    if (emailValid) {
      const result = await fetch("api/contact/mailing-list", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, firstName, lastName, twoLetterLanguageCode: locale }),
      });

      if (result.status === 200) {
        reset()
        enqueueSnackbar(RichText.asText(layout.subscribe_success_message), {
          variant: "success",
        });
      } else if (result.status === 400) {
        enqueueSnackbar(layout.subscribe_double_message, {
          variant: "warning",
        });
      } else {
        enqueueSnackbar(RichText.asText(layout.subscribe_error_message), {
          variant: "error",
        });
      }
    } else {
      enqueueSnackbar(RichText.asText(layout.subscribe_email_error_message), {
        variant: "warning",
      });
    }
  };

  const emailValidation = (e) => {
    setEmailValid(appConstants.emailRegex.test(e.target.value));
  };

  const viewport = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.newsUpdate}>
      <Container>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.2,
            duration: 0.8,
          }}
        >
          <Typography variant="h3" className="title">
            {RichText.asText(layout.subscribe_title)}
          </Typography>
        </motion.div>
        <motion.div
          variants={vTextFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
          transition={{
            type: "tween",
            delay: 0.4,
            duration: 0.8,
          }}
        >
          <Typography variant="subtitle2" className="description">
            {RichText.asText(layout.subscribe_description)}
          </Typography>
        </motion.div>
        <motion.div
          variants={vSlideRightFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
        >
          <form
            onSubmit={(formData) => {
              formData.preventDefault();
              send({ email, firstName, lastName });
            }}
          >
            <Paper className="subscribeWrapper splitWrapper">
              <InputBase
                className="input"
                placeholder={RichText.asText(
                  layout.first_name_placeholder_contact
                )}
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                type="text"
              />
              <InputBase
                className="input"
                placeholder={RichText.asText(
                  layout.last_name_placeholder_contact
                )}
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                type="text"
              />
            </Paper>
            <Paper className="subscribeWrapper">
              <InputBase
                onChange={(e) => {
                  setEmail(e.target.value);
                  emailValidation(e);
                }}
                className="input"
                type="email"
                placeholder={RichText.asText(layout.subscribe_placeholder)}
                value={email}
              />
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className="subButton"
                aria-label="directions"
                type="submit"
              >
                {RichText.asText(layout.subscribe_button_text)}
              </Button>
            </Paper>
          </form>
        </motion.div>
        <motion.div
          variants={vSlideBottomFade}
          initial={"offscreen"}
          whileInView={"onscreen"}
          viewport={viewport}
        >
          <div className="privacyText">
            {RichText.render(layout.privacy_text)}
          </div>
        </motion.div>
      </Container>
    </div>
  );
};

NewsUpdate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(NewsUpdate);
