import { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import {
  useVFIStoreState,
  useVFIStoreActions,
} from '../../store/vfi-easy-peasy-store'
import { CurrencyCode } from '../../models/braintree/DonationModels'
import CountryConfig from '../../models/braintree/CountryConfig'
import { useLayout } from '../../utils/layout-context'
import { useLocality } from '../../store/locality-settings-context'
import { useRouter } from 'next/router'
import { setCookie } from '../../services/locality-cookies'
import allCountries from '../../utils/countries'
import { geoData } from '../../services/ipInfo'
import regionHelpers from '../../services/regionHelpers'
import style from './style'
import { useTheme, withStyles } from '@material-ui/core/styles'
import { asText } from '@prismicio/helpers'

const LanguageSwitcher = ({ classes, open, setOpen }) => {
  const state = useVFIStoreState((state) => state)
  const actions = useVFIStoreActions((actions) => actions)

  const [currencyCode, setCurrencyCode] = useState<CurrencyCode>(
    state.currency?.code
  )
  const [countryCode, setCountryCode] = useState<string>(state.country?.code)
  const [componentCountry, setComponentCountry] = useState<CountryConfig>(
    state.country
  )
  const theme = useTheme()
  const { layout } = useLayout()
  const locality = useLocality()
  const router = useRouter()
  const [locale, setLocale] = useState('en')

  const syncUIWithStateAndClose = () => {
    setCurrencyCode(state.currency?.code)

    setCountryCode(state.country?.code)

    setComponentCountry(state.country)

    setLocale(findLocale())

    setOpen(false)
  }

  const submitLocalityData = (e) => {
    e.preventDefault()
    actions.setCountry(countryCode)
    actions.setCurrency(currencyCode)
    setCookie('NEXT_LOCALE', locale, document)
    // for certain languages, we launch the legacy site in a new browser tab
    if (layout.languages) {
      const languageConfig = layout.languages.find(
        (x) => x.language_code === locale
      )
      if (languageConfig && languageConfig.activate_link) {
        window.open(languageConfig.link.url)
        setOpen(false)
        return
      }
    }
    window.location.href = `/${locale.toLowerCase()}`
  }

  const findLocale = () => {
    return router.locale !== 'en-gb'
      ? router.locale.split('-')[0]
      : router.locale
  }

  const setPreferencesFromIP = () => {
    return geoData()
      .then((response) => response.json())
      .then((data) => {
        const foundCountry = allCountries.find(
          (countryObj) => countryObj.code == data.country_code
        )

        if (foundCountry) {
          actions.setCountry(foundCountry.code)
          actions.setCurrency(foundCountry.vfiCurrencyCode)
          setCurrencyCode(foundCountry.vfiCurrencyCode)
          setCountryCode(foundCountry.code)
          setComponentCountry(foundCountry)
          //todo - locality context we hopefully won't need any more, can just use state
          locality.setCountry(foundCountry.code)
        }
      })
  }

  useEffect(() => {
    syncUIWithStateAndClose()
  }, [state.currency.code])

  //EFFECTS - country
  useEffect(() => {
    if (!state.country) {
      setPreferencesFromIP()
    } else {
      setComponentCountry(allCountries.find((x) => x.code === countryCode))
      //can remove soon hopefully
      locality.setCountry(state.country.code)
    }
  }, [countryCode])

  return (
    <>
      <Dialog
        dir={theme.direction}
        open={open}
        onBackdropClick={syncUIWithStateAndClose}
        className={classes.dialogCust}
        maxWidth="xs"
      >
        <form
          onSubmit={(e) => {
            submitLocalityData(e)
          }}
        >
          <Paper className={classes.selectLanguage}>
            <div className="dialog-header">
              <div className="dialog-title">{asText(layout.popup_title)}</div>
              <div className="dialog-subtitle">
                {asText(layout.popup_subtitle)}
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel className="fieldTitle">
                  {asText(layout.preferred_language_placeholder)}
                </InputLabel>

                <TextField
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  select
                  value={locale}
                  onChange={(e) => {
                    setLocale(e.target.value)
                  }}
                >
                  {layout.languages.map((language, i) => {
                    return (
                      <MenuItem
                        className={classes.selectMenuItem}
                        key={i}
                        value={language.language_code}
                      >
                        {language.language_name}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="fieldTitle">
                  {asText(layout.preferred_currency_placeholder)}
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  select
                  value={currencyCode}
                  margin="dense"
                  onChange={(e) => {
                    setCurrencyCode(e.target.value as CurrencyCode)
                  }}
                >
                  {layout.currencies.map((currency, i) => (
                    <MenuItem
                      className={classes.selectMenuItem}
                      key={i}
                      value={currency.currency_code}
                    >
                      {currency.currency_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="fieldTitle">
                  {asText(layout.your_country_placeholder)}
                </InputLabel>
                <Autocomplete
                  id="country-select-demo"
                  style={{ width: '100%', cursor: 'pointer' }}
                  options={allCountries}
                  classes={{
                    option: classes.option,
                    paper: classes.autocompletePaper,
                  }}
                  autoHighlight
                  value={componentCountry}
                  getOptionLabel={(option) => option.label}
                  onChange={(e, v: CountryConfig) => {
                    setCountryCode(v.code)
                  }}
                  renderOption={(option) => (
                    <div className={classes.codeWrapper}>
                      <span>{regionHelpers.countryToFlag(option.code)}</span>
                      {option.label}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose a country"
                      variant="outlined"
                      margin="dense"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className="saveBtn"
              color="primary"
              type="submit"
            >
              {asText(layout.save_button_text)}
            </Button>

            <a className="cancel" onClick={() => syncUIWithStateAndClose()}>
              {asText(layout.cancel_button_text)}
            </a>
          </Paper>
        </form>
      </Dialog>
    </>
  )
}

export default withStyles(style)(LanguageSwitcher)
