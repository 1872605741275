import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useLayout } from "../../utils/layout-context";
import CustomLink from "../CustomLink";
import style from "./style";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { motion, MotionProps } from "framer-motion";
import { vPulseIn, vScaleFade, vTextFade } from "../../utils/animations";

const DownloadApp = ({
  classes,
  hideLearnMoreButton,
}: {
  classes: any;
  hideLearnMoreButton: boolean;
}) => {
  const { layout } = useLayout();

  const viewport: MotionProps["viewport"] = {
    once: true,
    margin: "-6%",
  };

  return (
    <div className={classes.download}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <motion.div
              variants={vTextFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
              transition={{
                type: "tween",
                delay: 0.2,
                duration: 0.8,
              }}
            >
              <Typography variant="h3" className="title">
                {RichText.asText(layout.download_app_title)}
              </Typography>
            </motion.div>
            <motion.div
              className="subtitle"
              variants={vTextFade}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
              transition={{
                type: "tween",
                delay: 0.4,
                duration: 0.8,
              }}
            >
              {RichText.render(layout.download_app_description)}
            </motion.div>
            <motion.div
              className="downloadApp"
              variants={vPulseIn}
              initial={"offscreen"}
              whileInView={"onscreen"}
              viewport={viewport}
            >
              <CustomLink
                key={"appStore"}
                link={layout.apple_download_link}
                className="appStore"
              >
                <img src={layout.apple_download_image.url} alt="app Play" />
              </CustomLink>

              <CustomLink
                key={"playStore"}
                link={layout.android_download_link}
                className="playStore"
              >
                <img
                  src={layout.android_download_image.url}
                  alt="google Play"
                />
              </CustomLink>
            </motion.div>
            {!hideLearnMoreButton && (
              <CustomLink
                key={"learnMoreButton"}
                link={layout.learn_more_button_link}
                className="learnMoreButton"
              >
                <motion.div
                  variants={vScaleFade}
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={viewport}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className="leanrMoreBtn"
                  >
                    {RichText.asText(layout.learn_more_button_text)}
                  </Button>
                </motion.div>
              </CustomLink>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

DownloadApp.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(style)(DownloadApp);
