import * as prismic from "@prismicio/client";
import { enableAutoPreviews } from "@prismicio/next";
import type { CreateClientConfig } from "@prismicio/next";
import type { LinkResolverFunction } from "@prismicio/helpers";
import type { ClientConfig } from "@prismicio/client";

export const repoName = "vfi-website";
export const apiEndpoint = `https://${repoName}.cdn.prismic.io/api/v2`;
export const accessToken = null;

export const routes: prismic.ClientConfig["routes"] = [
  {
    type: "page",
    uid: "homepage",
    path: '/'
  },
  {
    type: "page",
    path: `/:uid`,
  },
  {
    type: "blog_post",
    path: `/blog/:uid`,
  },
  {
    type: "event",
    path: `/event/:uid`,
  },
  {
    type: "project",
    path: `/project/:uid`,
  },
]

const resolver = (doc) => {
  if (doc.type === "page" && doc.uid === "homepage") {
    return `/`;
  }

  if (doc.type === "page") {
    return `/${doc.uid}`;
  }

  if (doc.type === "project") {
    return `/project/${doc.uid}`;
  }

  if (doc.type === "blog_post") {
    return `/blog/${doc.uid}`;
  }
  if (doc.type === "event") {
    return `/event/${doc.uid}`;
  }
  if (doc.type === "project_category") {
    return `/projects/category/${doc.uid}`;
  }
  if (doc.type === "blog_topic") {
    if (!doc.idx) {
      return `/blog/topic/${doc.uid}/1`;
    }
    return `/blog/topic/${doc.uid}/${doc.idx}`;
  }
  if (doc.type === "person") {
    return `/${doc.data.person_type.uid}/${doc.uid}`;
  }
  if (doc.type === "learn") {
    if (!doc.idx || doc.idx === 1) {
      return `/blog`;
    }
    return `/blog/page/${doc.idx}`;
  }
  return "/";
};

export const linkResolver: LinkResolverFunction = (doc) => resolver(doc);

export function createClient(config: CreateClientConfig) {
  const client = prismic.createClient(apiEndpoint, { routes, ...config } as ClientConfig);

  enableAutoPreviews({
    // @ts-ignore
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
}
