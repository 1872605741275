import React, {useContext, useState} from 'react'

const BasketContext = React.createContext<{setBasket:any, basket: any}>({
  setBasket: null,
  basket: null
})

//export const LayoutProvider = LayoutContext.Provider
export const BasketConsumer = BasketContext.Consumer

export const useBasket = () => {
  return useContext(BasketContext);
};

export const BasketProvider = ({ children, value }) => {

  return (
    <BasketContext.Provider value={{
        basket: value,
        setBasket:()=>{}
    }}>
        {children}
    </BasketContext.Provider>
  )
}

export default BasketContext