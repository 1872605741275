import React from "react";
import PropTypes from "prop-types";
import { RichText } from "prismic-reactjs";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import style from "./style";
import { useLayout } from "../../utils/layout-context";
import CustomLink from "../CustomLink";
const SubHeader = ({ classes }) => {
  const { layout } = useLayout();

  return (
    <div className={classes.subHeader}>
      <CustomLink link={layout.announcement_bar_button_link}>
        <Typography style={{ color: "white" }}>
          {RichText.asText(layout.announcement_bar_text)}
        </Typography>
      </CustomLink>
      <CustomLink link={layout.announcement_bar_button_link}>
        <Button variant="outlined">
          {RichText.asText(layout.announcement_bar_button_text)}
        </Button>
      </CustomLink>
    </div>
  );
};

SubHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default withStyles(style)(SubHeader);
