import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RichText } from 'prismic-reactjs'
import { withStyles } from '@material-ui/core/styles'
import { Container, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useLayout } from '../../utils/layout-context'
import CustomLink from '../CustomLink'
import style from './style'
import { useRouter } from 'next/router'
import { useVFIStoreState } from '../../store/vfi-easy-peasy-store'
import LanguageSwitcher from './language-switcher'

const Footer = ({ classes }) => {
  const state = useVFIStoreState((state) => state)
  const { layout } = useLayout()

  const router = useRouter()

  const [open, setOpen] = useState(false)

  const openLanguageSettingsDialog = () => {
    setOpen(true)
  }

  const getLanguageName = () => {
    let language = layout.languages.find(
      (languageObj) => languageObj.language_code == findLocale()
    )

    return language?.language_name
  }

  const findLocale = () => {
    return router.locale !== 'en-gb'
      ? router.locale.split('-')[0]
      : router.locale
  }

  return (
    <div className={classes.footer}>
      <div className={classes.footerTop}>
        <Container>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} container justify="center" alignItems="center">
              <Typography variant="h3" className="footerTitle">
                {RichText.asText(layout.engage_title)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            spacing={4}
            container
            justify="center"
            alignItems="center"
            className={classes.socialWrapper}
          >
            {layout.social_media_links.map((social, index) => {
              return (
                <Grid item sm={1} key={index}>
                  <CustomLink
                    link={social.social_media_link}
                    className="socialIcon"
                    key={index}
                  >
                    <div
                      className="icon"
                      style={{
                        mask: `url(${social.social_media_icon.url}) no-repeat center`,
                        WebkitMask: `url(${social.social_media_icon.url}) no-repeat center`,
                      }}
                    />
                  </CustomLink>
                </Grid>
              )
            })}
          </Grid>
          <Grid container spacing={4} className="linksSections">
            <Grid item xs={12} sm={4} md={4} container direction="column">
              <Typography variant="subtitle1" component="h6" className="title">
                {RichText.asText(layout.quick_links_title)}
              </Typography>
              {layout.quick_links.map((link, index) => {
                return (
                  <CustomLink link={link.link} key={index} className="link">
                    <Typography variant="body2">
                      {RichText.asText(link.link_title)}
                    </Typography>
                  </CustomLink>
                )
              })}
            </Grid>
            <Grid item xs={12} sm={4} md={4} container direction="column">
              <Typography variant="subtitle1" component="h6" className="title">
                {RichText.asText(layout.get_involved_title)}
              </Typography>
              {layout.get_involved_links.map((link, index) => {
                return (
                  <CustomLink link={link.link} key={index} className="link">
                    <Typography variant="body2">
                      {RichText.asText(link.link_title)}
                    </Typography>
                  </CustomLink>
                )
              })}
            </Grid>
            <Grid item xs={12} sm={4} md={4} container direction="column">
              <Typography variant="subtitle1" component="h6" className="title">
                {RichText.asText(layout.contact_us_title)}
              </Typography>
              <div className="address">
                {layout.contact_details.map((contact, index) => {
                  return (
                    <div key={index}>
                      {RichText.render(contact.contact_details1)}
                    </div>
                  )
                })}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.footerBottom}>
        <TextField
          InputLabelProps={{
            style: { color: '#fff' },
          }}
          variant="outlined"
          select
          disabled
          className="input"
          size="small"
          onClick={() => {
            openLanguageSettingsDialog()
          }}
          label={`${state.country?.label} / ${getLanguageName()} / ${
            state.currency?.symbol
          } ${state.currency?.code}`}
        ></TextField>
        <Grid item xs={12} container justify="center" alignItems="center">
          <CustomLink
            key={'logo-image-link'}
            link={layout.nav_logo_link}
            className="footerLinks"
          >
            <img src={layout.logo1.url} alt="" className="logoImg" />
          </CustomLink>
        </Grid>
        <div className="privacyLink">
          {layout.privacy_and_other_links.map((item, i) => (
            <CustomLink key={i} link={item.link} className="footerLinks">
              {RichText.asText(item.link_title)}
            </CustomLink>
          ))}
        </div>
        <div className="footerDisclosureText">
          <p>{layout.solicitation_disclosure_text}</p>
        </div>
        <a
          href={layout.wli_link.url}
          target="_blank"
          rel="nofollow"
          className="footerBottomLink"
        >
          {RichText.asText(layout.wli_text)}
        </a>
      </div>
      <LanguageSwitcher open={open} setOpen={setOpen} />
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
}
export default withStyles(style)(Footer)
