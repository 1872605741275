import React from 'react'
import PropTypes from 'prop-types'
import SubHeader from '../SubHeader'
import Header from '../Header'
import DownloadApp from '../DownloadApp'
import NewsUpdate from '../NewsUpdate'
import Footer from '../Footer'
import { useLayout } from '../../utils/layout-context'
import ExitPreviewButton from '../ExitPreview'
import { motion } from 'framer-motion'
import { useRouter } from 'next/router'
import DonateButton from '../DonateButton'

const Layout = ({ children, locale }) => {
  const { asPath } = useRouter()
  const { layout } = useLayout()

  const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  }

  if (asPath === '/slice-simulator') return <>{children}</>

  return (
    <div>
      <div>
        {layout.enabled___disabled && <SubHeader />}
        <Header />
      </div>
      <DonateButton />
      <motion.main
        initial="hidden"
        animate="enter"
        exit="exit"
        variants={variants}
        transition={{ duration: 0.5, type: 'linear' }}
      >
        {children}
      </motion.main>
      <DownloadApp
        hideLearnMoreButton={
          children?.props?.data?.hide_mobile_app_download_learn_more_button ||
          false
        }
      />
      <NewsUpdate />
      <Footer localeState={locale} />
      <ExitPreviewButton />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Layout
