import CountryConfig from "../models/braintree/CountryConfig"
import { CurrencyCode } from "../models/braintree/DonationModels"

const countries: CountryConfig[] = [
  {
    label: "Afghanistan",
    code: "AF",
    nativeCurrencyCode: "AFN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "93",
  },
  {
    label: "Algeria",
    code: "DZ",
    nativeCurrencyCode: "DZD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "213",
  },
  {
    label: "Madagascar",
    code: "MG",
    nativeCurrencyCode: "MGA",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "261",
  },
  {
    label: "Argentina",
    code: "AR",
    nativeCurrencyCode: "ARS",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "54",
  },
  {
    label: "Armenia",
    code: "AM",
    nativeCurrencyCode: "AMD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "374",
  },
  {
    label: "Aruba",
    code: "AW",
    nativeCurrencyCode: "AWG",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "297",
  },
  {
    label: "Australia",
    code: "AU",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "61",
  },
  {
    label: "Christmas Island",
    code: "CX",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "61",
  },
  {
    label: "Cocos (Keeling) Islands",
    code: "CC",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "61",
  },
  {
    label: "Heard Island And Mcdonald Islands",
    code: "HM",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1",
  },
  {
    label: "Kiribati",
    code: "KI",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "686",
  },
  {
    label: "Nauru",
    code: "NR",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "674",
  },
  {
    label: "Norfolk Island",
    code: "NF",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1",
  },
  {
    label: "Tuvalu",
    code: "TV",
    nativeCurrencyCode: "AUD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "688",
  },
  {
    label: "Azerbaijan",
    code: "AZ",
    nativeCurrencyCode: "AZM",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "994",
  },
  {
    label: "Bahamas",
    code: "BS",
    nativeCurrencyCode: "BSD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-242",
  },
  {
    label: "Bahrain",
    code: "BH",
    nativeCurrencyCode: "BHD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "973",
  },
  {
    label: "Thailand",
    code: "TH",
    nativeCurrencyCode: "THB",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "66",
  },
  {
    label: "Panama",
    code: "PA",
    nativeCurrencyCode: "PAB",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "507",
  },
  {
    label: "Barbados",
    code: "BB",
    nativeCurrencyCode: "BBD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-246",
  },
  {
    label: "Belarus",
    code: "BY",
    nativeCurrencyCode: "BYR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "375",
  },
  {
    label: "Belize",
    code: "BZ",
    nativeCurrencyCode: "BZD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "501",
  },
  {
    label: "Bermuda",
    code: "BM",
    nativeCurrencyCode: "BMD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-441",
  },
  {
    label: "Venezuela",
    code: "VE",
    nativeCurrencyCode: "VEB",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "58",
  },
  {
    label: "Bolivia",
    code: "BO",
    nativeCurrencyCode: "BOB",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "591",
  },
  {
    label: "Brazil",
    code: "BR",
    nativeCurrencyCode: "BRL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "55",
  },
  {
    label: "Brunei Darussalam",
    code: "BN",
    nativeCurrencyCode: "BND",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "673",
  },
  {
    label: "Bulgaria",
    code: "BG",
    nativeCurrencyCode: "BGN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "359",
  },
  {
    label: "Burundi",
    code: "BI",
    nativeCurrencyCode: "BIF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "257",
  },
  {
    label: "Canada",
    code: "CA",
    nativeCurrencyCode: "CAD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1",
  },
  {
    label: "Cape Verde",
    code: "CV",
    nativeCurrencyCode: "CVE",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "238",
  },
  {
    label: "Cayman Islands",
    code: "KY",
    nativeCurrencyCode: "KYD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-345",
  },
  {
    label: "Ghana",
    code: "GH",
    nativeCurrencyCode: "GHC",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "233",
  },
  {
    label: "Benin",
    code: "BJ",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "229",
  },
  {
    label: "Burkina Faso",
    code: "BF",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "226",
  },
  {
    label: "Cote D'ivoire",
    code: "CI",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "225",
  },
  {
    label: "Guinea-bissau",
    code: "GW",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "245",
  },
  {
    label: "Mali",
    code: "ML",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "223",
  },
  {
    label: "Niger",
    code: "NE",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "227",
  },
  {
    label: "Senegal",
    code: "SN",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "221",
  },
  {
    label: "Togo",
    code: "TG",
    nativeCurrencyCode: "XOF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "228",
  },
  {
    label: "Cameroon",
    code: "CM",
    nativeCurrencyCode: "XAF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "237",
  },
  {
    label: "Central African Republic",
    code: "CF",
    nativeCurrencyCode: "XAF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "236",
  },
  {
    label: "Chad",
    code: "TD",
    nativeCurrencyCode: "XAF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "235",
  },
  {
    label: "Congo",
    code: "CG",
    nativeCurrencyCode: "XAF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "242",
  },
  {
    label: "Equatorial Guinea",
    code: "GQ",
    nativeCurrencyCode: "XAF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "240",
  },
  {
    label: "Gabon",
    code: "GA",
    nativeCurrencyCode: "XAF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "241",
  },
  {
    label: "French Polynesia",
    code: "PF",
    nativeCurrencyCode: "XPF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "689",
  },
  {
    label: "New Caledonia",
    code: "NC",
    nativeCurrencyCode: "XPF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "687",
  },
  {
    label: "Wallis And Futuna",
    code: "WF",
    nativeCurrencyCode: "XPF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "681",
  },
  {
    label: "Chile",
    code: "CL",
    nativeCurrencyCode: "CLP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "56",
  },
  {
    label: "Colombia",
    code: "CO",
    nativeCurrencyCode: "COP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "57",
  },
  {
    label: "Comoros",
    code: "KM",
    nativeCurrencyCode: "KMF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "269",
  },
  {
    label: "Bosnia And Herzegovina",
    code: "BA",
    nativeCurrencyCode: "BAM",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "387",
  },
  {
    label: "Nicaragua",
    code: "NI",
    nativeCurrencyCode: "NIO",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "505",
  },
  {
    label: "Costa Rica",
    code: "CR",
    nativeCurrencyCode: "CRC",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "506",
  },
  {
    label: "Croatia",
    code: "HR",
    nativeCurrencyCode: "HRK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "385",
  },
  {
    label: "Cuba",
    code: "CU",
    nativeCurrencyCode: "CUP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "53",
  },
  {
    label: "Cyprus",
    code: "CY",
    nativeCurrencyCode: "CYP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "357",
  },
  {
    label: "Czech Republic",
    code: "CZ",
    nativeCurrencyCode: "CZK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "420",
  },
  {
    label: "Gambia",
    code: "GM",
    nativeCurrencyCode: "GMD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "220",
  },
  {
    label: "Denmark",
    code: "DK",
    nativeCurrencyCode: "DKK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "45",
  },
  {
    label: "Faroe Islands",
    code: "FO",
    nativeCurrencyCode: "DKK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "298",
  },
  {
    label: "Greenland",
    code: "GL",
    nativeCurrencyCode: "DKK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "299",
  },
  {
    label: "Macedonia, The Former Yugoslav Republic Of",
    code: "MK",
    nativeCurrencyCode: "MKD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "389",
  },
  {
    label: "Djibouti",
    code: "DJ",
    nativeCurrencyCode: "DJF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "253",
  },
  {
    label: "Sao Tome And Principe",
    code: "ST",
    nativeCurrencyCode: "STD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "239",
  },
  {
    label: "Dominican Republic",
    code: "DO",
    nativeCurrencyCode: "DOP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-809, 1-829, 1-849",
  },
  {
    label: "Viet Nam",
    code: "VN",
    nativeCurrencyCode: "VND",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "84",
  },
  {
    label: "Dominica",
    code: "DM",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-767",
  },
  {
    label: "Grenada",
    code: "GD",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-473",
  },
  {
    label: "Montserrat",
    code: "MS",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-664",
  },
  {
    label: "Saint Kitts And Nevis",
    code: "KN",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-869",
  },
  {
    label: "Saint Lucia",
    code: "LC",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-758",
  },
  {
    label: "Saint Vincent And The Grenadines",
    code: "VC",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-784",
  },
  {
    label: "Anguilla",
    code: "AI",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-264",
  },
  {
    label: "Antigua And Barbuda",
    code: "AG",
    nativeCurrencyCode: "XCD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-268",
  },
  {
    label: "Egypt",
    code: "EG",
    nativeCurrencyCode: "EGP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "20",
  },
  {
    label: "El Salvador",
    code: "SV",
    nativeCurrencyCode: "SVC",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "503",
  },
  {
    label: "Ethiopia",
    code: "ET",
    nativeCurrencyCode: "ETB",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "251",
  },
  {
    label: "Andorra",
    code: "AD",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "376",
  },
  {
    label: "Austria",
    code: "AT",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "43",
  },
  {
    label: "Belgium",
    code: "BE",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "32",
  },
  {
    label: "Finland",
    code: "FI",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "358",
  },
  {
    label: "France",
    code: "FR",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "33",
  },
  {
    label: "French Guiana",
    code: "GF",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "1",
  },
  {
    label: "French Southern Territories ",
    code: "TF",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "1",
  },
  {
    label: "Germany",
    code: "DE",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "49",
  },
  {
    label: "Greece",
    code: "GR",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "30",
  },
  {
    label: "Guadeloupe",
    code: "GP",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "1",
  },
  {
    label: "Holy See (Vatican City State)",
    code: "VA",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "379",
  },
  {
    label: "Ireland",
    code: "IE",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "353",
  },
  {
    label: "Italy",
    code: "IT",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "39",
  },
  {
    label: "Luxembourg",
    code: "LU",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "352",
  },
  {
    label: "Martinique",
    code: "MQ",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "1",
  },
  {
    label: "Mayotte",
    code: "YT",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "262",
  },
  {
    label: "Monaco",
    code: "MC",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "377",
  },
  {
    label: "Netherlands",
    code: "NL",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "31",
  },
  {
    label: "Portugal",
    code: "PT",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "351",
  },
  {
    label: "Reunion",
    code: "RE",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "262",
  },
  {
    label: "Saint Pierre And Miquelon",
    code: "PM",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "508",
  },
  {
    label: "San Marino",
    code: "SM",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "378",
  },
  {
    label: "Serbia & Montenegro",
    code: "CS",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "1",
  },
  {
    label: "Spain",
    code: "ES",
    nativeCurrencyCode: "EUR",
    vfiCurrencyCode: CurrencyCode.Euro,
    dialingCode: "34",
  },
  {
    label: "Falkland Islands (Malvinas)",
    code: "FK",
    nativeCurrencyCode: "FKP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "500",
  },
  {
    label: "Fiji",
    code: "FJ",
    nativeCurrencyCode: "FJD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "679",
  },
  {
    label: "Hungary",
    code: "HU",
    nativeCurrencyCode: "HUF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "36",
  },
  {
    label: "Congo, The Democratic Republic Of The",
    code: "CD",
    nativeCurrencyCode: "CDF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "243",
  },
  {
    label: "Gibraltar",
    code: "GI",
    nativeCurrencyCode: "GIP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "350",
  },
  {
    label: "Haiti",
    code: "HT",
    nativeCurrencyCode: "HTG",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "509",
  },
  {
    label: "Paraguay",
    code: "PY",
    nativeCurrencyCode: "PYG",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "595",
  },
  {
    label: "Guinea",
    code: "GN",
    nativeCurrencyCode: "GNF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "224",
  },
  {
    label: "Guinea-bissau",
    code: "GW",
    nativeCurrencyCode: "GWP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "245",
  },
  {
    label: "Guyana",
    code: "GY",
    nativeCurrencyCode: "GYD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "592",
  },
  {
    label: "Hong Kong",
    code: "HK",
    nativeCurrencyCode: "HKD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "852",
  },
  {
    label: "Ukraine",
    code: "UA",
    nativeCurrencyCode: "UAH",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "380",
  },
  {
    label: "Iceland",
    code: "IS",
    nativeCurrencyCode: "ISK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "354",
  },
  {
    label: "Bhutan",
    code: "BT",
    nativeCurrencyCode: "INR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "975",
  },
  {
    label: "India",
    code: "IN",
    nativeCurrencyCode: "INR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "91",
  },
  {
    label: "Iran, Islamic Republic Of",
    code: "IR",
    nativeCurrencyCode: "IRR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "98",
  },
  {
    label: "Iraq",
    code: "IQ",
    nativeCurrencyCode: "IQD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "964",
  },
  {
    label: "Jamaica",
    code: "JM",
    nativeCurrencyCode: "JMD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-876",
  },
  {
    label: "Jordan",
    code: "JO",
    nativeCurrencyCode: "JOD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "962",
  },
  {
    label: "Kenya",
    code: "KE",
    nativeCurrencyCode: "KES",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "254",
  },
  {
    label: "Papua New Guinea",
    code: "PG",
    nativeCurrencyCode: "PGK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "675",
  },
  {
    label: "Lao People's Democratic Republic",
    code: "LA",
    nativeCurrencyCode: "LAK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "856",
  },
  {
    label: "Estonia",
    code: "EE",
    nativeCurrencyCode: "EEK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "372",
  },
  {
    label: "Kuwait",
    code: "KW",
    nativeCurrencyCode: "KWD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "965",
  },
  {
    label: "Malawi",
    code: "MW",
    nativeCurrencyCode: "MWK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "265",
  },
  {
    label: "Zambia",
    code: "ZM",
    nativeCurrencyCode: "ZMK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "260",
  },
  {
    label: "Angola",
    code: "AO",
    nativeCurrencyCode: "AOA",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "244",
  },
  {
    label: "Myanmar",
    code: "MM",
    nativeCurrencyCode: "MMK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "95",
  },
  {
    label: "Georgia",
    code: "GE",
    nativeCurrencyCode: "GEL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "995",
  },
  {
    label: "Latvia",
    code: "LV",
    nativeCurrencyCode: "LVL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "371",
  },
  {
    label: "Lebanon",
    code: "LB",
    nativeCurrencyCode: "LBP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "961",
  },
  {
    label: "Albania",
    code: "AL",
    nativeCurrencyCode: "ALL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "355",
  },
  {
    label: "Honduras",
    code: "HN",
    nativeCurrencyCode: "HNL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "504",
  },
  {
    label: "Sierra Leone",
    code: "SL",
    nativeCurrencyCode: "SLL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "232",
  },
  {
    label: "Romania",
    code: "RO",
    nativeCurrencyCode: "ROL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "40",
  },
  {
    label: "Bulgaria",
    code: "BG",
    nativeCurrencyCode: "BGL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "359",
  },
  {
    label: "Liberia",
    code: "LR",
    nativeCurrencyCode: "LRD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "231",
  },
  {
    label: "Swaziland",
    code: "SZ",
    nativeCurrencyCode: "SZL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "268",
  },
  {
    label: "Lithuania",
    code: "LT",
    nativeCurrencyCode: "LTL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "370",
  },
  {
    label: "Lesotho",
    code: "LS",
    nativeCurrencyCode: "LSL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "266",
  },
  {
    label: "Libyan Arab Jamahiriya",
    code: "LY",
    nativeCurrencyCode: "LYD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "218",
  },
  {
    label: "Madagascar",
    code: "MG",
    nativeCurrencyCode: "MGF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "261",
  },
  {
    label: "Malaysia",
    code: "MY",
    nativeCurrencyCode: "MYR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "60",
  },
  {
    label: "Malta",
    code: "MT",
    nativeCurrencyCode: "MTL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "356",
  },
  {
    label: "Turkmenistan",
    code: "TM",
    nativeCurrencyCode: "TMM",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "993",
  },
  {
    label: "Mauritius",
    code: "MU",
    nativeCurrencyCode: "MUR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "230",
  },
  {
    label: "Mozambique",
    code: "MZ",
    nativeCurrencyCode: "MZM",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "258",
  },
  {
    label: "Mexico",
    code: "MX",
    nativeCurrencyCode: "MXN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "52",
  },
  {
    label: "Mexico",
    code: "MX",
    nativeCurrencyCode: "MXV",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "52",
  },
  {
    label: "Moldova, Republic Of",
    code: "MD",
    nativeCurrencyCode: "MDL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "373",
  },
  {
    label: "Morocco",
    code: "MA",
    nativeCurrencyCode: "MAD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "212",
  },
  {
    label: "Western Sahara",
    code: "EH",
    nativeCurrencyCode: "MAD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "212",
  },
  {
    label: "Bolivia",
    code: "BO",
    nativeCurrencyCode: "BOV",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "591",
  },
  {
    label: "Nigeria",
    code: "NG",
    nativeCurrencyCode: "NGN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "234",
  },
  {
    label: "Eritrea",
    code: "ER",
    nativeCurrencyCode: "ERN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "291",
  },
  {
    label: "Namibia",
    code: "NA",
    nativeCurrencyCode: "NAD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "264",
  },
  {
    label: "Nepal",
    code: "NP",
    nativeCurrencyCode: "NPR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "977",
  },
  {
    label: "Netherlands Antilles",
    code: "AN",
    nativeCurrencyCode: "ANG",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "599",
  },
  {
    label: "Israel",
    code: "IL",
    nativeCurrencyCode: "ILS",
    vfiCurrencyCode: CurrencyCode.Shekel,
    dialingCode: "972",
  },
  {
    label: "Taiwan, Province Of China",
    code: "TW",
    nativeCurrencyCode: "TWD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "886",
  },
  {
    label: "Cook Islands",
    code: "CK",
    nativeCurrencyCode: "NZD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "682",
  },
  {
    label: "New Zealand",
    code: "NZ",
    nativeCurrencyCode: "NZD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "64",
  },
  {
    label: "Niue",
    code: "NU",
    nativeCurrencyCode: "NZD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "683",
  },
  {
    label: "Pitcairn",
    code: "PN",
    nativeCurrencyCode: "NZD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "64",
  },
  {
    label: "Tokelau",
    code: "TK",
    nativeCurrencyCode: "NZD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "690",
  },
  {
    label: "Bhutan",
    code: "BT",
    nativeCurrencyCode: "BTN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "975",
  },
  {
    label: "Antarctica",
    code: "AQ",
    nativeCurrencyCode: "",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "672",
  },
  {
    label: "Korea, Democratic People's Republic Of",
    code: "KP",
    nativeCurrencyCode: "KPW",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "850",
  },
  {
    label: "Bouvet Island",
    code: "BV",
    nativeCurrencyCode: "NOK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1",
  },
  {
    label: "Norway",
    code: "NO",
    nativeCurrencyCode: "NOK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "47",
  },
  {
    label: "Svalbard And Jan Mayen",
    code: "SJ",
    nativeCurrencyCode: "NOK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "47",
  },
  {
    label: "Peru",
    code: "PE",
    nativeCurrencyCode: "PEN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "51",
  },
  {
    label: "Mauritania",
    code: "MR",
    nativeCurrencyCode: "MRO",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "222",
  },
  {
    label: "Tonga",
    code: "TO",
    nativeCurrencyCode: "TOP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "676",
  },
  {
    label: "Pakistan",
    code: "PK",
    nativeCurrencyCode: "PKR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "92",
  },
  {
    label: "Macao",
    code: "MO",
    nativeCurrencyCode: "MOP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "853",
  },
  {
    label: "Uruguay",
    code: "UY",
    nativeCurrencyCode: "UYU",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "598",
  },
  {
    label: "Philippines",
    code: "PH",
    nativeCurrencyCode: "PHP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "63",
  },
  {
    label: "United Kingdom",
    code: "GB",
    nativeCurrencyCode: "GBP",
    vfiCurrencyCode: CurrencyCode.Pound,
    dialingCode: "44",
  },
  {
    label: "Botswana",
    code: "BW",
    nativeCurrencyCode: "BWP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "267",
  },
  {
    label: "Qatar",
    code: "QA",
    nativeCurrencyCode: "QAR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "974",
  },
  {
    label: "Guatemala",
    code: "GT",
    nativeCurrencyCode: "GTQ",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "502",
  },
  {
    label: "Lesotho",
    code: "LS",
    nativeCurrencyCode: "ZAR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "266",
  },
  {
    label: "Namibia",
    code: "NA",
    nativeCurrencyCode: "ZAR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "264",
  },
  {
    label: "South Africa",
    code: "ZA",
    nativeCurrencyCode: "ZAR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "27",
  },
  {
    label: "Oman",
    code: "OM",
    nativeCurrencyCode: "OMR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "968",
  },
  {
    label: "Cambodia",
    code: "KH",
    nativeCurrencyCode: "KHR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "855",
  },
  {
    label: "Maldives",
    code: "MV",
    nativeCurrencyCode: "MVR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "960",
  },
  {
    label: "Indonesia",
    code: "ID",
    nativeCurrencyCode: "IDR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "62",
  },
  {
    label: "Russian Federation",
    code: "RU",
    nativeCurrencyCode: "RUB",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "7",
  },
  {
    label: "Russian Federation",
    code: "RU",
    nativeCurrencyCode: "RUR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "7",
  },
  {
    label: "Rwanda",
    code: "RW",
    nativeCurrencyCode: "RWF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "250",
  },
  {
    label: "Saint Helena",
    code: "SH",
    nativeCurrencyCode: "SHP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "290",
  },
  {
    label: "Saudi Arabia",
    code: "SA",
    nativeCurrencyCode: "SAR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "966",
  },
  {
    label: "Serbia & Montenegro",
    code: "CS",
    nativeCurrencyCode: "CSD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1",
  },
  {
    label: "Seychelles",
    code: "SC",
    nativeCurrencyCode: "SCR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "248",
  },
  {
    label: "Singapore",
    code: "SG",
    nativeCurrencyCode: "SGD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "65",
  },
  {
    label: "Slovakia",
    code: "SK",
    nativeCurrencyCode: "SKK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "421",
  },
  {
    label: "Solomon Islands",
    code: "SB",
    nativeCurrencyCode: "SBD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "677",
  },
  {
    label: "Kyrgyzstan",
    code: "KG",
    nativeCurrencyCode: "KGS",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "996",
  },
  {
    label: "Somalia",
    code: "SO",
    nativeCurrencyCode: "SOS",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "252",
  },
  {
    label: "Tajikistan",
    code: "TJ",
    nativeCurrencyCode: "TJS",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "992",
  },
  {
    label: "Sri Lanka",
    code: "LK",
    nativeCurrencyCode: "LKR",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "94",
  },
  {
    label: "Sudan",
    code: "SD",
    nativeCurrencyCode: "SDD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "249",
  },
  {
    label: "Suriname",
    code: "SR",
    nativeCurrencyCode: "SRD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "597",
  },
  {
    label: "Sweden",
    code: "SE",
    nativeCurrencyCode: "SEK",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "46",
  },
  {
    label: "Liechtenstein",
    code: "LI",
    nativeCurrencyCode: "CHF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "423",
  },
  {
    label: "Switzerland",
    code: "CH",
    nativeCurrencyCode: "CHF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "41",
  },
  {
    label: "Syrian Arab Republic",
    code: "SY",
    nativeCurrencyCode: "SYP",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "963",
  },
  {
    label: "Bangladesh",
    code: "BD",
    nativeCurrencyCode: "BDT",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "880",
  },
  {
    label: "Samoa",
    code: "WS",
    nativeCurrencyCode: "WST",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "685",
  },
  {
    label: "Tanzania, United Republic Of",
    code: "TZ",
    nativeCurrencyCode: "TZS",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "255",
  },
  {
    label: "Kazakhstan",
    code: "KZ",
    nativeCurrencyCode: "KZT",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "7",
  },
  {
    label: "Slovenia",
    code: "SI",
    nativeCurrencyCode: "SIT",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "386",
  },
  {
    label: "Trinidad And Tobago",
    code: "TT",
    nativeCurrencyCode: "TTD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-868",
  },
  {
    label: "Mongolia",
    code: "MN",
    nativeCurrencyCode: "MNT",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "976",
  },
  {
    label: "Tunisia",
    code: "TN",
    nativeCurrencyCode: "TND",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "216",
  },
  {
    label: "Turkey",
    code: "TR",
    nativeCurrencyCode: "TRL",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "90",
  },
  {
    label: "United Arab Emirates",
    code: "AE",
    nativeCurrencyCode: "AED",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "971",
  },
  {
    label: "Uganda",
    code: "UG",
    nativeCurrencyCode: "UGX",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "256",
  },
  {
    label: "Ecuador",
    code: "EC",
    nativeCurrencyCode: "ECV",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "593",
  },
  {
    label: "Chile",
    code: "CL",
    nativeCurrencyCode: "CLF",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "56",
  },
  {
    label: "American Samoa",
    code: "AS",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-684",
  },
  {
    label: "British Indian Ocean Territory",
    code: "IO",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "246",
  },
  {
    label: "Ecuador",
    code: "EC",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "593",
  },
  {
    label: "El Salvador",
    code: "SV",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "503",
  },
  {
    label: "Guam",
    code: "GU",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-671",
  },
  {
    label: "Haiti",
    code: "HT",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "509",
  },
  {
    label: "Marshall Islands",
    code: "MH",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "692",
  },
  {
    label: "Micronesia, Federated States Of",
    code: "FM",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "691",
  },
  {
    label: "Northern Mariana Islands",
    code: "MP",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-670",
  },
  {
    label: "Palau",
    code: "PW",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "680",
  },
  {
    label: "Panama",
    code: "PA",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "507",
  },
  {
    label: "Puerto Rico",
    code: "PR",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-787, 1-939",
  },
  {
    label: "Timor-leste",
    code: "TL",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "670",
  },
  {
    label: "Turks And Caicos Islands",
    code: "TC",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-649",
  },
  {
    label: "United States",
    code: "US",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1",
  },
  {
    label: "United States Minor Outlying Islands",
    code: "UM",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1",
  },
  {
    label: "Virgin Islands, British",
    code: "VG",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-284",
  },
  {
    label: "Virgin Islands, U.S.",
    code: "VI",
    nativeCurrencyCode: "USD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "1-340",
  },
  {
    label: "Uzbekistan",
    code: "UZ",
    nativeCurrencyCode: "UZS",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "998",
  },
  {
    label: "Vanuatu",
    code: "VU",
    nativeCurrencyCode: "VUV",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "678",
  },
  {
    label: "Korea, Republic Of",
    code: "KR",
    nativeCurrencyCode: "KRW",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "82",
  },
  {
    label: "Yemen",
    code: "YE",
    nativeCurrencyCode: "YER",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "967",
  },
  {
    label: "Japan",
    code: "JP",
    nativeCurrencyCode: "JPY",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "81",
  },
  {
    label: "China",
    code: "CN",
    nativeCurrencyCode: "CNY",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "86",
  },
  {
    label: "Zimbabwe",
    code: "ZW",
    nativeCurrencyCode: "ZWD",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "263",
  },
  {
    label: "Poland",
    code: "PL",
    nativeCurrencyCode: "PLN",
    vfiCurrencyCode: CurrencyCode.USDollar,
    dialingCode: "48",
  },
]

export default countries
