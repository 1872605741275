import { Variants } from "framer-motion";

export const vTextFade: Variants = {
  offscreen: {
    opacity: 0,
    y: 50,
  },
  onscreen: {
    opacity: 1,
    y: 0,
  },
};

export const vFadeIn: Variants = {
  offscreen: {
    opacity: 0,
  },
  onscreen: {
    opacity: 1,
    transition: {
      type: "tween",
      delay: 0.4,
      duration: 0.8,
    },
  },
};

export const vTextBlurWhite: Variants = {
  offscreen: {
    opacity: 0.5,
    textShadow: "0 0 10px white",
    color: "transparent",
  },
  onscreen: {
    opacity: 1,
    textShadow: "none",
    color: "white",
    transition: {
      type: "tween",
      delay: 0.5,
      duration: 2,
    },
  },
};

export const vSlideTopFade: Variants = {
  offscreen: {
    opacity: 0,
    y: -100,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      delay: 0.4,
      duration: 0.8,
    },
  },
};

export const vSlideTopFadeCustom: Variants = {
  offscreen: {
    opacity: 0,
    y: -100,
  },
  onscreen: {
    opacity: 1,
    y: 0,
  },
};

export const vSlideBottomFade: Variants = {
  offscreen: {
    opacity: 0,
    y: 100,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: "tween",
      delay: 0.4,
      duration: 0.8,
    },
  },
};

export const vSlideBottomFadeCustom: Variants = {
  offscreen: {
    opacity: 0,
    y: 100,
  },
  onscreen: {
    opacity: 1,
    y: 0,
  },
};

export const vSlideRightFade: Variants = {
  offscreen: {
    opacity: 0,
    x: 100,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      delay: 0.4,
      duration: 0.8,
    },
  },
};

export const vSlideRightFadeCustom: Variants = {
  offscreen: {
    opacity: 0,
    x: 100,
  },
  onscreen: {
    opacity: 1,
    x: 0,
  },
};

export const vSlideLeftFade: Variants = {
  offscreen: {
    opacity: 0,
    x: -100,
  },
  onscreen: {
    opacity: 1,
    x: 0,
    transition: {
      type: "tween",
      delay: 0.4,
      duration: 0.8,
    },
  },
};

export const vSlideLeftFadeCustom: Variants = {
  offscreen: {
    opacity: 0,
    x: -100,
  },
  onscreen: {
    opacity: 1,
    x: 0,
  },
};

export const vScaleFade: Variants = {
  offscreen: {
    opacity: 0,
    scale: 0,
  },
  onscreen: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "spring",
      delay: 0.5,
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export const vScaleFadeCustom: Variants = {
  offscreen: {
    opacity: 0,
    scale: 0,
  },
  onscreen: {
    opacity: 1,
    scale: 1,
  },
};

export const vBlink: Variants = {
  offscreen: {
    opacity: 0.8,
    scale: 0.95,
  },
  onscreen: {
    opacity: 1,
    scale: 1,
    transition: {
      type: "tween",
      repeat: Infinity,
      repeatType: "reverse",
      duration: 0.8,
    },
  },
};

export const vBounce: Variants = {
  offscreen: {
    y: -16,
  },
  onscreen: {
    y: 0,
    transition: {
      type: "tween",
      repeat: Infinity,
      repeatType: "reverse",
      duration: 2,
    },
  },
};

export const vPulseIn: Variants = {
  offscreen: {
    scale: 2,
    opacity: 0,
  },
  onscreen: {
    scale: 1,
    opacity: 1,
    transition: {
      type: "tween",
      delay: 0.5,
      duration: 0.8,
    },
  },
};

export const vPulseInCustom: Variants = {
  offscreen: {
    scale: 2,
    opacity: 0,
  },
  onscreen: {
    scale: 1,
    opacity: 1,
  },
};
