import { makeStyles } from "@material-ui/core/styles"
import styles from "./style"
import { useLayout } from "../../utils/layout-context"
import { PrismicNextLink } from '@prismicio/next'
import Image from "next/image"

const useStyles = makeStyles(styles)

const DonateButton = () => {
  const classes = useStyles()
  const { layout } = useLayout()

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <PrismicNextLink field={layout.floating_donate_link} className={classes.donateButton}>
          {layout.floating_donate_icon?.url && (
            <Image 
              src={layout.floating_donate_icon.url}
              alt={layout.floating_donate_icon.alt || ''}
              width={20}
              height={20}
            />
          )}
          <span>{layout.floating_donate_text}</span>
        </PrismicNextLink>
      </div>
    </div>
  )
}

export default DonateButton