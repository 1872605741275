import { BraintreeBranch } from "../models/braintree/DonationModels";
import countries from "../utils/countries.json";
import allCountries from "../utils/countries"
import CountryConfig from "../models/braintree/CountryConfig";
import BraintreeConfig from "./braintree/shared/braintree-config";
const regionHelpers =  {
  
  getCountryFromCode:(code: string) : CountryConfig => {

    if (code){
      let chosenCountry = allCountries.find((country) => country.code == code);

      if (chosenCountry){
        return chosenCountry
      }
    }
    
    return allCountries[0]
  },
    countryToFlag: (isoCode)=> {
        return typeof String.fromCodePoint !== "undefined"
        ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
        : isoCode;
    },
    findBranchFromCountryCode :(code: string) : BraintreeBranch => {

      let branch : BraintreeBranch

      const countriesMapped = countries.countries.find((country) => {

        for (const [key, value] of Object.entries(country)) {

          //todo - add some validation that we are getting a real braintree branch here
          if (country[key].includes(code)) {
            branch=key as BraintreeBranch
            return true
          } else {
            branch=BraintreeBranch.US
            return false
          }
        }
      });

     if(!BraintreeConfig.validateBranch(branch)){
       throw Error(branch + " is not a valid braintree branch")
     }

      return branch
    }
    
}

export default regionHelpers