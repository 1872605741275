import {
  routerToPrismicLanguages,
  prismicLanguagesToRouter,
} from '../services/languageMap';

function getLanguage(locale) {
  const currentLanguage = locale;

  return routerToPrismicLanguages.hasOwnProperty(currentLanguage)
    ? {
        prismicLanguage: routerToPrismicLanguages[currentLanguage],
        language: currentLanguage,
      }
    : { prismicLanguage: 'en-us', language: 'en' };
}

function getExtensionLanguage(locale) {
  const currentLanguage = locale;

  return prismicLanguagesToRouter.hasOwnProperty(currentLanguage)
    ? {
        prismicLanguage: prismicLanguagesToRouter[currentLanguage],
        language: currentLanguage,
      }
    : { prismicLanguage: 'en-us', language: 'en' };
}

export { getExtensionLanguage, getLanguage };
